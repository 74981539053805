import { createRouter, createWebHashHistory } from 'vue-router';
import HomeView from '@/views/HomeView.vue';
import BonusView from '@/views/BonusView.vue';
import ReferalView from '@/views/ReferalView.vue';
import ShopView from '@/views/ShopView.vue';
import TaskView from '@/views/TaskView.vue';

const routes = [
  { path: '/', name: 'Home', component: HomeView },
  { path: '/bonus', name: 'Bonus', component: BonusView },
  { path: '/referal', name: 'Referal', component: ReferalView },
  { path: '/shop', name: 'Shop', component: ShopView },
  { path: '/task', name: 'Task', component: TaskView },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
