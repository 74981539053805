<template>
    <div class="wrapper">

        <div class="header">
            <component :is="currentHeader" @open-ranking="openRanking" />
        </div>

        <div class="body">

            <Modal
                v-if="modalState.visible"
                :visible="modalState.visible"
                :type="modalState.type"
                :title="modalState.title"
                :message="modalState.message"
                :icon="modalState.icon"
                :hasAction="modalState.hasAction"
                :actionText="modalState.actionText"
                @close="hideModal"
                @action="handleAction"
            />

            <router-view></router-view>

            <RankingView 
                v-if="isRankingVisible" 
                @close-ranking="closeRanking" 
            />
        
        </div>

        <div class="menu">

            <button
                class="menu__btn home"
                @click="navigateTo('Home')"
                :class="{ active: activePage === 'Home' }"
            >
                <img src="~@/assets/images/menu/main.png" alt="" />
            </button>

            <button
                class="menu__btn shop"
                @click="navigateTo('Shop')"
                :class="{ active: activePage === 'Shop' }"
            >
                <img src="~@/assets/images/menu/shop.png" alt="" />
            </button>

            <button
                class="menu__btn referal"
                @click="navigateTo('Referal')"
                :class="{ active: activePage === 'Referal' }"
            >
                <img src="~@/assets/images/menu/referal.png" alt="" />
            </button>

            <button
                class="menu__btn task"
                @click="navigateTo('Task')"
                :class="{ active: activePage === 'Task' }"
            >
                <img src="~@/assets/images/menu/task.png" alt="" />
            </button>
            
            <button
                class="menu__btn bonus"
                @click="navigateTo('Bonus')"
                :class="{ active: activePage === 'Bonus' }"
            >
                <img src="~@/assets/images/menu/bonus.png" alt="" />
            </button>
        
    </div>
    
    </div>
</template>

<script>
import { inject } from "vue";
import HeaderDefault from "@/views/components/HeaderDefault.vue";
import HeaderReferal from "@/views/components/HeaderReferal.vue";
import RankingView from "@/views/RankingView.vue";


export default {
    name: "App",
    components: {
        HeaderDefault,
        HeaderReferal,
        RankingView
    },
    data(){
        return {
            isRankingVisible: false, // Видимость рейтинга
        };
    },
    setup() {
        const modalState = inject("modalState");

        const hideModal = () => {
            modalState.visible = false;
        };

        const handleAction = () => {
            if (modalState.callback) {
                modalState.callback();
            }
            hideModal();
        };

        return {
            modalState,
            hideModal,
            handleAction,
        };
    },
    computed: {
        currentHeader() {
            return this.$route.name === "Referal" ? "HeaderReferal" : "HeaderDefault";
        },
        activePage() {
            return this.$route.name;
        },
    },
    methods: {
        navigateTo(page) {
            this.$router.push({ name: page });
        },
        openRanking() {
            this.isRankingVisible = true;
        },
        closeRanking() {
            this.isRankingVisible = false;
        },
    },
};
</script>
