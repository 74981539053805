<template>

    <div class="header__top">

        <div class="user__info">

            <div class="avatar">
                <img src="~@/assets/images/header/avatar.png" alt="">
            </div>

            <div class="nickname">Username</div>

            <div class="ranking__btn" @click="$emit('open-ranking')">
                <img src="~@/assets/images/header/cup.png" alt="">
            </div>

        </div>

        <div class="wallet__link">

            <div class="icon">
                <svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M19.1 5.004C19.0433 5.00067 18.983 4.99933 18.919 5H16.394C14.326 5 12.557 6.628 12.557 8.75C12.557 10.872 14.327 12.5 16.394 12.5H18.919C18.983 12.5007 19.0437 12.4993 19.101 12.496C19.526 12.4704 19.9272 12.2911 20.2299 11.9916C20.5325 11.6921 20.716 11.2927 20.746 10.868C20.75 10.808 20.75 10.743 20.75 10.683V6.817C20.75 6.757 20.75 6.692 20.746 6.632C20.716 6.20726 20.5325 5.80793 20.2299 5.50842C19.9272 5.2089 19.525 5.02963 19.1 5.004ZM16.173 9.75C16.705 9.75 17.136 9.302 17.136 8.75C17.136 8.198 16.705 7.75 16.173 7.75C15.64 7.75 15.209 8.198 15.209 8.75C15.209 9.302 15.64 9.75 16.173 9.75Z" fill="#7A5646"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M18.918 14C18.9526 13.9986 18.987 14.0054 19.0184 14.0198C19.0499 14.0342 19.0775 14.0558 19.099 14.0829C19.1206 14.11 19.1354 14.1418 19.1424 14.1757C19.1493 14.2096 19.1481 14.2446 19.139 14.278C18.939 14.99 18.62 15.598 18.109 16.108C17.36 16.858 16.411 17.189 15.239 17.347C14.099 17.5 12.644 17.5 10.806 17.5H8.694C6.856 17.5 5.4 17.5 4.261 17.347C3.089 17.189 2.14 16.857 1.391 16.109C0.643 15.36 0.311 14.411 0.153 13.239C1.19209e-07 12.099 0 10.644 0 8.806V8.694C0 6.856 1.19209e-07 5.4 0.153 4.26C0.311 3.088 0.643 2.139 1.391 1.39C2.14 0.642 3.089 0.31 4.261 0.152C5.401 -4.47035e-08 6.856 0 8.694 0H10.806C12.644 0 14.1 1.19209e-07 15.239 0.153C16.411 0.311 17.36 0.643 18.109 1.391C18.62 1.903 18.939 2.51 19.139 3.222C19.1481 3.25537 19.1493 3.29042 19.1424 3.32432C19.1354 3.35822 19.1206 3.39 19.099 3.41708C19.0775 3.44417 19.0499 3.46579 19.0184 3.4802C18.987 3.4946 18.9526 3.50139 18.918 3.5H16.394C13.557 3.5 11.057 5.74 11.057 8.75C11.057 11.76 13.557 14 16.394 14H18.918ZM3.75 4C3.55109 4 3.36032 4.07902 3.21967 4.21967C3.07902 4.36032 3 4.55109 3 4.75C3 4.94891 3.07902 5.13968 3.21967 5.28033C3.36032 5.42098 3.55109 5.5 3.75 5.5H7.75C7.94891 5.5 8.13968 5.42098 8.28033 5.28033C8.42098 5.13968 8.5 4.94891 8.5 4.75C8.5 4.55109 8.42098 4.36032 8.28033 4.21967C8.13968 4.07902 7.94891 4 7.75 4H3.75Z" fill="#7A5646"/>
                </svg>                        
            </div>

            <p class="wallet__link--text">Кошелек</p>

        </div>

    </div>

    <span class="profit">Доход за 5 мин</span>

    <div class="user__data">

        <div class="user__data--item diamond glitter">
            <img src="~@/assets/images/diamond__wallet.png" alt="">
            <p>2 000</p>
        </div>

        <div class="user__data--item ton glitter">
            <img src="~@/assets/images/ton__wallet.png" alt="">
            <p>2 000</p>
        </div>

        <div class="user__data--item block glitter">
            <img src="~@/assets/images/block__wallet.png" alt="">
            <p>+ 100</p>
        </div>

    </div>

</template>

<script>
export default {
    name: "HeaderDefault",
};
</script>