<template>

    <div class="tasks">
        
        <button class="btn__primary add__reklama">

            <div class="icon">
                <img src="~@/assets/images/plus.png" alt="">
            </div>

            Добавить рекламу

        </button>

        <p class="page__title">Активные задания</p>
        <p class="page__span">Выполняйте задания и получайте лайки!</p>

        <div class="task__list">

            <div class="task__list__item">

                <div class="task__item__info">

                    <div class="task__image">
                        <img src="~@/assets/images/task/item-1.png" alt="">
                    </div>

                    <div class="task__data">
                        <p class="task__data--name">Blum - запустить игру</p>
                        <p class="task__data--reward">
                            <img src="~@/assets/images/diamond__wallet.png" alt="">
                            <span class="count">+2</span> алмаза
                        </p>
                    </div>

                </div>

                <div class="task__item__status">

                    <div class="no__started">
                        <img src="~@/assets/images/arrow-right.png" alt="">
                    </div>

                </div>
                
            </div>

            <div class="task__list__item">

                <div class="task__item__info">

                    <div class="task__image">
                        <img src="~@/assets/images/task/item-2.png" alt="">
                    </div>

                    <div class="task__data">
                        <p class="task__data--name">Booms - запустить игру</p>
                        <p class="task__data--reward">
                            <img src="~@/assets/images/diamond__wallet.png" alt="">
                            <span class="count">+2</span> алмаза
                        </p>
                    </div>

                </div>

                <div class="task__item__status">

                    <div class="process">

                        <div class="loader-icon">
                            <div class="outer-half-circle"></div>
                            <div class="inner-half-circle"></div>
                        </div>

                    </div>

                </div>

            </div>

            <div class="task__list__item">

                <div class="task__item__info">

                    <div class="task__image">
                        <img src="~@/assets/images/task/item-3.png" alt="">
                    </div>

                    <div class="task__data">
                        <p class="task__data--name">Agent 301 - запустить игру</p>
                        <p class="task__data--reward">
                            <img src="~@/assets/images/diamond__wallet.png" alt="">
                            <span class="count">+2</span> алмаза
                        </p>
                    </div>

                </div>

                <div class="task__item__status">

                    <div class="get__reward">

                        <button class="btn__primary">Получить</button>

                    </div>

                </div>

            </div>

            <div class="task__list__item">

                <div class="task__item__info">

                    <div class="task__image">
                        <img src="~@/assets/images/task/item-1.png" alt="">
                    </div>

                    <div class="task__data">
                        <p class="task__data--name">Blum - запустить игру</p>
                        <p class="task__data--reward">
                            <img src="~@/assets/images/diamond__wallet.png" alt="">
                            <span class="count">+2</span> алмаза
                        </p>
                    </div>

                </div>

                <div class="task__item__status">

                    <div class="completed">
                        <img src="~@/assets/images/completed.png" alt="">
                    </div>

                </div>

            </div>

        </div>
        
    </div>

</template>

<script>
    import '../styles/components/Task.scss';

    export default {
        name: "TaskView",
    };
</script>
