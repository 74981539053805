<template>
    
    <div class="header__referal">

        <div class="header__left">
            <p class="total__profit score">5 000</p>
            <p class="total__profit">Доход за все время</p>
        </div>

        <div class="header__right">

            <div class="current__profit">

                <div class="icon"><img src="~@/assets/images/diamond__wallet.png" alt=""></div>
                
                <div class="text">
                    <p class="current__profit score">1 000</p>
                    <p class="current__profit">Текущий доход</p>
                </div>

            </div>

            <button class="btn__primary">Собрать</button>

        </div>

    </div>

</template>

<script>
export default {
    name: "HeaderReferal",
};
</script>