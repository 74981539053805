import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ModalPlugin from "./plugins/ModalPlugin";
import './styles/main.scss';

router.beforeEach((to, from, next) => {
    if (to.name === 'Home') {
        document.body.className = 'home-page';
    } else {
        document.body.className = 'default-page';
    }
    next();
});

createApp(App).use(router).use(store).use(ModalPlugin).mount('#app');
