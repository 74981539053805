<template>
    <div class="exchange">
        <div class="modal__title">
            <p class="title">Обменник</p>
        </div>

        <div v-if="!isExchangeFormVisible" class="exchange__list">
            <div class="exchange__list__item" @click="selectExchange('blocks-to-diamonds')">
                <p class="exchange__item--name">БЛОКИ В АЛМАЗЫ</p>
                <div class="exchange__list__icons">
                    <div class="exchange__icons__image" from>
                        <img src="~@/assets/images/block__wallet.png" alt="">
                    </div>
                    <span in><img src="~@/assets/images/shop/in.png" alt=""></span>
                    <div class="exchange__icons__image" to>
                        <img src="~@/assets/images/diamond__wallet.png" alt="">
                    </div>
                </div>
            </div>

            <div class="exchange__list__item" @click="selectExchange('blocks-to-ton')">
                <p class="exchange__item--name">БЛОКИ В TON</p>
                <div class="exchange__list__icons">
                    <div class="exchange__icons__image" from>
                        <img src="~@/assets/images/block__wallet.png" alt="">
                    </div>
                    <span in><img src="~@/assets/images/shop/in.png" alt=""></span>
                    <div class="exchange__icons__image" to>
                        <img src="~@/assets/images/ton__wallet.png" alt="">
                    </div>
                </div>
            </div>
        </div>

        <div v-if="isExchangeFormVisible" class="exchange__form">
            <div class="exchange__input__list">
                <div class="exchange__input__item">
                    <p class="title">
                        <span><img :src="exchangeForm.fromImage" alt=""></span>
                        Вы платите
                    </p>
                    <div class="exchange__item--input">
                        <input type="number" min="0" v-model="amount" placeholder="0" />
                        <p>{{ exchangeForm.fromCurrency }}</p>
                    </div>
                    <div class="exchange__item--rate">{{ exchangeForm.rate }}</div>
                </div>

                <div class="exchange__input__item">
                    <p class="title">
                        <span><img :src="exchangeForm.toImage" alt=""></span>
                        Вы получите
                    </p>
                    <div class="exchange__item--input">
                        <input type="number" :value="calculatedAmount" disabled />
                        <p>{{ exchangeForm.toCurrency }}</p>
                    </div>
                </div>
            </div>

            <button class="btn__primary" @click="confirmExchange">Обменять</button>
            <button class="btn__back" @click="backToList">
                <img src="~@/assets/images/arrow-left.png" alt="" />
            </button>
        </div>
    </div>
</template>

<script>
import '../../../styles/components/shop/exchange.scss';

export default {
    name: "Exchange",
    data() {
        return {
            isExchangeFormVisible: false,
            amount: 0,
            exchangeForm: {
                title: '',
                fromCurrency: '',
                toCurrency: '',
                fromImage: '',
                toImage: '',
                rate: '',
            },
        };
    },
    computed: {
        calculatedAmount() {
            if (this.exchangeForm.fromCurrency === 'Блоки' && this.exchangeForm.toCurrency === 'Алмазы') {
                return (this.amount * 10000);
            }
            if (this.exchangeForm.fromCurrency === 'Блоки' && this.exchangeForm.toCurrency === 'TON') {
                return (this.amount * 0.000001);
            }
            return 0;
        }
    },
    methods: {
        selectExchange(exchangeType) {
            if (exchangeType === 'blocks-to-diamonds') {
                this.exchangeForm = {
                    title: 'Блоки в Алмазы',
                    fromCurrency: 'Блоки',
                    toCurrency: 'Алмазы',
                    fromImage: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAdCAYAAAC5UQwxAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAANzSURBVHgBzZZbaxNBFMf/s9lNm2LbeKnWGxYUX9UXwSfrowgaUcE3q/gqWvwA6pPgU/0CXhAvoKAggiDo9kWrFYyoFaHqaltNDbZJmzSX3Z3xzKTdbNptm9AG/MNkJjsz53fOmZPNMNSohyIW1dG400E+fpg9SqFGsWoWlSBNJwARE2A7qY9Ob44LwATcXhcaOXDPWszWvMAn4hhFYcQ0iL1ktBNViNZZGjnBgUc6nPcH2P34gsDH4ngnRXCIhjFqHViyWIpBmNSbDuzew+SALh9f+nD64vatubMCPIpllYgOJezYwGAhZo3YJj3Yp4C/RsNbRhLhaGuzi1UrbWxeX0QLjZei4YSNvngOI6NOCU3plr3uX5SeDKn2/WcjIo0cq1c62LyhoPpqVCgKvBvIIf65oMZSTYNF1We3GSkPGElyq2FCwG4CMu0htAy54AZ5mQ9j+HfYg69fW6QMUCnpogKUHHMwQIY/fy2BmISFS+URTjgIZTgig4UycNMrOyWn05s0BTSmBJr+CjWWyuU1BZZNqr3Nxrq2Ilw+hbcfp7y0Ked/2mh5k8Po0RYVXcM0kENYHpBpIg3OoNE+CZuRHLd9cpDYpYPr5YJOJA3VRpIpFDI2DDJoryo5x4rl/c3xvIKVzpCXIxQOlS/9gJoTnFpRGXcpmIY0V8/+0DFwWtmYFtBz3ItcqpWiUee/OwIRZoEwqRBjPqBOQF72THOEinbj21Kq1nyhtNBQp4i5wSqAUjJta55mkDzYXDJOIAn0yxVIe0B6K1gclUb8ah0ueyoLa0XC9aAapVBFskJT6ZRFEijNGPeAtUie68Z+h1Lsgn2bxFTKVs8lrP3BRMUZ+nXn+csfiis/untuWKhRKu227/vMz2Gxfb5xzX81NciaC2SibkCBsm2tzKtnhGwuUDBmoU5iQRGivmcYBKzbGVouxNU5wPNXr3W7QpzE9Et2mdQziciuO2a/d9UIvNNcOXOqK8RwgaY7FrI2+PU7MtlswIxIcWjnbpt9N2fPBL7Pnr15F9+/Z8dN4bI8udRB4MCrx9h4CkXbrkTRJcqBtu+u2dcbtGfRa+Llc10dYaFdoDLumj0XEGHPLfN190L2qrqXzgf2AS0w3nXrRX/vYnaqBvrBhhu6zhg6JXAym6UrIDt5z+yzUE/Jwuo+sv8s/nf9A194sZOtU1OVAAAAAElFTkSuQmCC',
                    toImage: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAYCAYAAAARfGZ1AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAP6SURBVHgBlVVdaBxVFP7u/OzO/iQ7SZo2EZNuqDUoFaZJVsRKEwuCQtUI9aGiEBHRR19FCnnxSbD4IliQoiD6ZtoHob5EEEUtki3GamtjpqSJmx+T2d3u7vzduZ7ZxNjs7CbxwOHO3Hvud8797jnnMuwhmZwxL4BsszURYKL8c/6TVnuVVgv6Y0Y2HLO9/fC533yzpOimiqz1Q97E/xGKeLo9ZwhzcVEEQdBU35w8J0KbdO74aFPnjRMaRay5GHvq5KjOwFBNqjC5vcMmzlhd2YOHkdo4Due3ufG2YaODKJq61441glMkF2mYuPThBYwOj+CzSgFlwXfY9Cnxul5YuoFvrWUU3vsYzk0TkocOK5+3IpGncoYhCzz/2pkXs916J/TebqwLFxxiG7RNUjCoJGAGDq54FtbiCpJ6BrKyCWMnWYaGKLjKYQQyJocfOoaXTj+LOV7FHe7sBGcyRmLtMJ01XHGLQEJBKqFvgzeKhBay4Du45dXQKal1Ch5QE+iQFSwQ/700dzbWhSOSVrfNPHcKXRMvIOaJyfYR4/ye4KXAxwr30CVvgh+kMSPJ2BAenUBCTkmTY7luqw1mkT4xFH5O0C2Ob9OiE9dC4PypE49n33rlVWDgPlyurda9DigaZt0KFMqMJIvG8eevN7C4YEJ/ZBCpnkO4HaFFQBcMYwc7DmSfGBqGlkphibvQKKpOeZNLn7yXAh7R6roFe3mNQBhUTYs4b1mhGkUaryuDIwT2I30fvIOgWsvi7fc3aMs3kbMGdJQQdNWzsebZ+wL1imVw3wdTFfBKLZzSQ41E3ivHiBIJ75rX8Hu1iJf7H0abEtsV/M5X0whcr/4tav8FFAFfp2qco3TzYgrUII5Vy8KGz5uClululEwaAXEgKHK/eJc6ZdAa/BoVz6fOCh0qTWdL48upr+FXqk3BE0f7kTx2FMmt/9J3MwjuVlHb+pe1/h7yDP1IX59Vs2vZ2YTAUlJB6Y95VJdW4Lku5FSiqQa05hcpVdNJMFlCafpH8FLZ8hYKX1DTym83Ln3ImKDyv3j/G2fRc+YZzH9+GZWFv3Dg9CjkZKJp5MWffoFtLqLjyUcR6+7E7dfPhdNm6Wp+oCktXGKoEM98i2fGWhbxtnjL63SRTmQ+Au7aNqpWsZ5a+xVv5W9wSsdGkf/90A71WFxmU+EF2NdvGUyWoert0A73gilyU1BO2cFC+5nrsGdvEpo87jvuR95SobAjcmryJg2mJMRY+IS0PX0SUnz3/JaoaEIbbpXgF9ZAXF+6dz3yEumGoYdNX5PUKaiKsZsDwanbh3cTi43b5WLe/n5mR++KcL71TFnxnGEJx63n7V4SoGbZV2camyL+Abi3vifzVV7nAAAAAElFTkSuQmCC',
                    rate: '10000 Блоков = 1 Алмазы',
                };
            } else if (exchangeType === 'blocks-to-ton') {
                this.exchangeForm = {
                    title: 'Блоки в TON',
                    fromCurrency: 'Блоки',
                    toCurrency: 'TON',
                    fromImage: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAdCAYAAAC5UQwxAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAANzSURBVHgBzZZbaxNBFMf/s9lNm2LbeKnWGxYUX9UXwSfrowgaUcE3q/gqWvwA6pPgU/0CXhAvoKAggiDo9kWrFYyoFaHqaltNDbZJmzSX3Z3xzKTdbNptm9AG/MNkJjsz53fOmZPNMNSohyIW1dG400E+fpg9SqFGsWoWlSBNJwARE2A7qY9Ob44LwATcXhcaOXDPWszWvMAn4hhFYcQ0iL1ktBNViNZZGjnBgUc6nPcH2P34gsDH4ngnRXCIhjFqHViyWIpBmNSbDuzew+SALh9f+nD64vatubMCPIpllYgOJezYwGAhZo3YJj3Yp4C/RsNbRhLhaGuzi1UrbWxeX0QLjZei4YSNvngOI6NOCU3plr3uX5SeDKn2/WcjIo0cq1c62LyhoPpqVCgKvBvIIf65oMZSTYNF1We3GSkPGElyq2FCwG4CMu0htAy54AZ5mQ9j+HfYg69fW6QMUCnpogKUHHMwQIY/fy2BmISFS+URTjgIZTgig4UycNMrOyWn05s0BTSmBJr+CjWWyuU1BZZNqr3Nxrq2Ilw+hbcfp7y0Ked/2mh5k8Po0RYVXcM0kENYHpBpIg3OoNE+CZuRHLd9cpDYpYPr5YJOJA3VRpIpFDI2DDJoryo5x4rl/c3xvIKVzpCXIxQOlS/9gJoTnFpRGXcpmIY0V8/+0DFwWtmYFtBz3ItcqpWiUee/OwIRZoEwqRBjPqBOQF72THOEinbj21Kq1nyhtNBQp4i5wSqAUjJta55mkDzYXDJOIAn0yxVIe0B6K1gclUb8ah0ueyoLa0XC9aAapVBFskJT6ZRFEijNGPeAtUie68Z+h1Lsgn2bxFTKVs8lrP3BRMUZ+nXn+csfiis/untuWKhRKu227/vMz2Gxfb5xzX81NciaC2SibkCBsm2tzKtnhGwuUDBmoU5iQRGivmcYBKzbGVouxNU5wPNXr3W7QpzE9Et2mdQziciuO2a/d9UIvNNcOXOqK8RwgaY7FrI2+PU7MtlswIxIcWjnbpt9N2fPBL7Pnr15F9+/Z8dN4bI8udRB4MCrx9h4CkXbrkTRJcqBtu+u2dcbtGfRa+Llc10dYaFdoDLumj0XEGHPLfN190L2qrqXzgf2AS0w3nXrRX/vYnaqBvrBhhu6zhg6JXAym6UrIDt5z+yzUE/Jwuo+sv8s/nf9A194sZOtU1OVAAAAAElFTkSuQmCC',
                    toImage: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAYCAYAAAAPtVbGAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAR+SURBVHgBjVbPT1xFHP/O7HvLQlUeNFWoIHvqQU0LJxNJCtV68UCxJy9STao9mFhIDyYmREhroraNbWo1MSZFTZr0hP4FLKSJRmMB24vRhN2E2tiDLIWF3Tdv5ut3vvPeLku3m04yzDI78/l8P99fswKajODaSlBSrSeEhGH6tx8Qs3YfAYogxJJEWNKAP0Xvduea4YhHgW9F6XFEOC1ABAzbFALzqPAN9X7PEjwOif/Naj8Bz7LVJgIwxtlOjGS9W5OrIp4yRasEAzgdneqdakriX13tB1/MCWMC0CFApAAtEZpYzC4Cu0oJIuUDeDSlByhgRr3X805DkszX97I6pRdpI2DwcBtAVeoJLCHfkm4yGTCB8FvJygyTIuIldapnIsGWyQcjwjkhZMCARgNo5dbETYnl7C4Rk4Bzp9bOAGsQfUdHx9NfrYzWKfGvrrwtPP8a+xbpQlgGrGzBoc4UBK2+87kFt2AWKGVjEJ8lkvm7WyDSbQDpVuc2a0cU5sPK2gBMDBQ9x2Q+dpbG4gh07OA++PZoFzzOyD8I4aUbq1DExIVWPWR9b88J8sdlL/PF7WET53+VKOXBBwOdsHx/G87k7saCBYw93w5jL+6Fozf+js8iHNrXBheP7IeDz+yBhfs6zsDYSdpYl132SPxQnVnCZcz8PxV460AbFNYVFIpldtfQ/gwfWSisVzNr7IW9vFco4c4Qx0P3O9/oqL+amtUakHDu1iavky93scXVRAD2N8ejrz1NJB1w5faGI0k8gVW8IHPhVp9ErQPOEMQ6NUXCvHJng0EO9z4Zp7KJDXSpbA0obER0rgT1JYdxUmiITDlLZUo1YS/Zzbr2IeDLO1sMMjnYHXeP2vfHDnSQAZ1w9vcH9SoSJdo4Y7atPyKdZ/lM9LCak/NrMNT7BBzOdnAMksS48MpzbMAPf5VrNcMEhhUgd4wQhKmsWyVLXNkq3FFQNaKFe4pmBS6+2kfgPm+PDXRzPE7m1nYRYFycynULmmr6yJJHvWlZKCo+1UL15bkik6Imn0DO/LwOvx1/GtoznovF4LPw/Z8lWPhXufMJAToXIRMQZqRynEbR9Gs5UlGESon61RZJrMTtxFTV/PGfpuBuQvYpp6SP1nOLmw0JQFEQLA71PjRqhklctoTTdpOJaForIIp2kAlK6RIUQxezs5TehU2sgdtz5H9rpG1HYKeq5PUnr3/HIbR/zM3rv6QG3xylS10OFOOCrmVMmbKlYhDKxD356zqtxvnfxpHcg1ZBmQwsOxXG6HG8eX3Z5WkyPpzN+tJbBK8lAL/FNTvbur00hcWrZZbL5dg92qV/5GIAyfOgo0vqs5EJ2HGjOvyPZukdT82B9APOJD/NJPzZduJq20DnIquC3h4uAS4DZcln1KcjjR+tOkUg58j8LAPbjEuUcLruigW/PUn86LH6fHRiN2TDHxIwPhukPXkaJU65B0vWHq1ECatxZMSZIyumovMj843gGpPUyLIpaYZFSh4TQmQJjbsqClEUiHm6naOn9sfo/PH5ZjD/A4nRZ+AuBD+5AAAAAElFTkSuQmCC',
                    rate: '10000 Блоков = 0.01 TON',
                };
            }
            this.isExchangeFormVisible = true;
        },
        confirmExchange() {
            if (this.amount <= 0) {
                alert('Введите правильную сумму для обмена.');
                return;
            }
            alert(`Обменяли ${this.amount} ${this.exchangeForm.fromCurrency} на ${this.calculatedAmount} ${this.exchangeForm.toCurrency}`);
        },
        backToList() {
            this.isExchangeFormVisible = false;
        },
    },
};
</script>
