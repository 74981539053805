<template>
    <div class="shop">
        <div class="shop__list">
            <button
                class="shop__list__btn glitter"
                @click="openBoostModal"
            >
                <img src="~@/assets/images/shop/boost.png" alt="" />
                <p>Прокачаться</p>
            </button>

            <button
                class="shop__list__btn glitter"
                @click="openExchangeModal"
            >
                <img src="~@/assets/images/shop/exchange.png" alt="" />
                <p>Обменник</p>
            </button>

            <button
                class="shop__list__btn glitter"
                @click="openDiamondModal"
            >
                <img src="~@/assets/images/shop/diamond.png" alt="" />
                <p>Купить алмазы</p>
            </button>

            <button
                class="shop__list__btn glitter"
                @click="openWithdrawalModal"
            >
                <img src="~@/assets/images/shop/withdraw.png" alt="" />
                <p>Вывести деньги</p>
            </button>
        </div>

        <Modal
            v-if="modalState.visible"
            :type="modalState.type"
            :visible="modalState.visible"
            @close="closeModal"
        >
            <template #default>
                <component :is="currentModalContent" />
            </template>
        </Modal>
    </div>
</template>

<script>
import '../styles/components/Shop.scss';
import Boost from './components/shop/Boost.vue';
import Exchange from './components/shop/Exchange.vue';
import BuyDiamond from './components/shop/BuyDiamond.vue';
import Withdrawal from './components/shop/Withdrawal.vue';

export default {
    name: "ShopView",
    inject: ["modalState"],
    data() {
        return {
            currentModalContent: null,
        };
    },
    methods: {
        openModal(content) {
            this.currentModalContent = content;
            this.$modal.show({ type: "bottom" });
        },
        closeModal() {
            this.$modal.hide();
            this.currentModalContent = null;
        },
        openBoostModal() {
            this.openModal(Boost);
        },
        openExchangeModal() {
            this.openModal(Exchange);
        },
        openDiamondModal() {
            this.openModal(BuyDiamond);
        },
        openWithdrawalModal() {
            this.openModal(Withdrawal);
        },
    },
};
</script>
