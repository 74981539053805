<template>
    <div
        v-if="visible"
        :class="['modal', type]"
        @click.self="close"
    >
        <div class="modal__content">
            <button class="modal__close" @click="close">
                <img src="~@/assets/images/modal/close.png" alt="" />
            </button>

            <template v-if="type === 'center'">
                <div class="modal__image">
                    <img :src="icon" alt="" />
                </div>
                <p class="title">{{ title }}</p>
                <p class="text" v-html="message"></p>

                <button v-if="hasAction" class="btn btn__primary" @click="action">
                    {{ actionText }}
                </button>
            </template>

            <template v-else>
                <slot></slot>
            </template>
        </div>
    </div>
</template>

<script>
import '../../styles/components/Modal.scss';

export default {
    name: "Modal",
    props: {
        visible: { type: Boolean, default: false },
        type: { type: String, default: "center" },
        title: { type: String, default: "" },
        message: { type: String, default: "" },
        icon: { type: String, default: "" },
        hasAction: { type: Boolean, default: false },
        actionText: { type: String, default: "OK" },
    },
    emits: ["close", "action"],
    methods: {
        close() {
            this.$emit("close");
        },
        action() {
            this.$emit("action");
        },
    },
};
</script>