import { reactive } from "vue";
import Modal from "@/views/components/Modal.vue";

export default {
  install(app) {
    const state = reactive({
      visible: false,
      type: "center",
      content: null, // Динамический контент
      title: "",
      message: "",
      icon: "",
      hasAction: false,
      actionText: "",
      callback: null,
    });

    app.component("Modal", Modal);

    app.config.globalProperties.$modal = {
      show(options) {
        Object.assign(state, options, { visible: true });
      },
      hide() {
        Object.assign(state, {
          visible: false,
          content: null, // Очищаем динамический контент
          callback: null,
        });
      },
    };

    app.provide("modalState", state);
  },
};
