<template>
    <div class="modal__title">
        <p class="title">Вывод средств</p>
    </div>

    <div class="withdrawal">
        <div class="withdrawal__form">
            <div class="withdrawal__input__item">
                <div class="withdrawal__input__header">
                    <p class="title">
                        <span><img src="~@/assets/images/ton__wallet.png" alt="" /></span>
                        Сумма вывода
                    </p>
                    <div v-if="showAmountError && amount < 10" class="min error">Мин. 10 TON</div>
                </div>

                <div class="withdrawal__item--input">
                    <input
                        type="number"
                        min="0"
                        v-model="amount"
                        placeholder="0"
                        @input="validateAmount"
                        @focus="showAmountError = true"
                    />
                    <p>TON</p>
                </div>
                <div class="withdrawal__item--balance">Баланс: 100 TON</div>
            </div>

            <div class="withdrawal__input__address">
                <p class="title">Адрес кошелька TON</p>
                <div class="withdrawal__item--input">
                    <input
                        type="text"
                        v-model="address"
                        :class="{'error-input': addressError}"
                        placeholder="QUF123HFSDF123412FSDOHAM"
                        @input="handleAddressInput"
                        @focus="hasAddressInteracted = true"
                    />
                    <img
                        v-if="addressError"
                        src="~@/assets/images/error.png"
                        alt="error"
                        class="error-icon"
                    />
                </div>
                <div v-if="addressError" class="error-message">
                    Адрес кошелька указан не полностью или неверно
                </div>
            </div>
        </div>

        <button class="btn__primary" :disabled="!isFormValid">Вывести</button>
    </div>
</template>

<script>
import '../../../styles/components/shop/withdraw.scss';

export default {
    name: "Withdrawal",
    data() {
        return {
            amount: 0,
            address: "",
            addressError: false,
            isFormValid: false,
            showAmountError: false,
            hasAddressInteracted: false,
        };
    },
    methods: {
        validateAmount() {
            this.updateFormValidity();
        },

        handleAddressInput() {
            this.hasAddressInteracted = true;
            this.validateAddress();
        },

        validateAddress() {
            const isValid =
                this.address.length >= 20 &&
                this.address.length <= 100 &&
                /[a-zA-Z]/.test(this.address);

            this.addressError = !isValid;
            this.updateFormValidity();
        },

        updateFormValidity() {
            this.isFormValid =
                this.amount >= 10 &&
                !this.addressError &&
                this.hasAddressInteracted &&
                this.address.trim() !== "";
        },
    },
};
</script>
