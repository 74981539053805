<template>

    <div class="modal__title">
        <p class="title">Прокачать канал</p>
        <p class="span">Доход за 5 минут</p>
    </div>

    <div class="boost__list">

        <div class="boost__list__item">

            <div class="left">

                <div class="boost__item--icon">
                    <img src="~@/assets/images/block__wallet.png" alt="">
                </div>

                <div class="boost__item--count">+ <span>2000</span> Блоков</div>

            </div>

            <button class="btn__primary">
                <span>20</span>
                <img src="~@/assets/images/diamond__wallet.png" alt="">
            </button>

        </div>

        <div class="boost__list__item">

            <div class="left">

                <div class="boost__item--icon">
                    <img src="~@/assets/images/block__wallet.png" alt="">
                </div>

                <div class="boost__item--count">+ <span>20000</span> Блоков</div>

            </div>

            <button class="btn__primary">
                <span>200</span>
                <img src="~@/assets/images/diamond__wallet.png" alt="">
            </button>

        </div>

        <div class="boost__list__item">

            <div class="left">

                <div class="boost__item--icon">
                    <img src="~@/assets/images/block__wallet.png" alt="">
                </div>

                <div class="boost__item--count">+ <span>200000</span> Блоков</div>

            </div>

            <button class="btn__primary">
                <span>2000</span>
                <img src="~@/assets/images/diamond__wallet.png" alt="">
            </button>

        </div>

        <div class="boost__list__item">

            <div class="left">

                <div class="boost__item--icon">
                    <img src="~@/assets/images/block__wallet.png" alt="">
                </div>

                <div class="boost__item--count">+ <span>2000000</span> Блоков</div>

            </div>

            <button class="btn__primary">
                <span>20000</span>
                <img src="~@/assets/images/diamond__wallet.png" alt="">
            </button>

        </div>

        <div class="boost__list__item">

            <div class="left">

                <div class="boost__item--icon">
                    <img src="~@/assets/images/block__wallet.png" alt="">
                </div>

                <div class="boost__item--count">+ <span>2000</span> Блоков</div>

            </div>

            <button class="btn__primary">
                <span>20</span>
                <img src="~@/assets/images/diamond__wallet.png" alt="">
            </button>

        </div>

        <div class="boost__list__item">

            <div class="left">

                <div class="boost__item--icon">
                    <img src="~@/assets/images/block__wallet.png" alt="">
                </div>

                <div class="boost__item--count">+ <span>2000</span> Блоков</div>

            </div>

            <button class="btn__primary">
                <span>20</span>
                <img src="~@/assets/images/diamond__wallet.png" alt="">
            </button>

        </div>

        <div class="boost__list__item">

            <div class="left">

                <div class="boost__item--icon">
                    <img src="~@/assets/images/block__wallet.png" alt="">
                </div>

                <div class="boost__item--count">+ <span>2000</span> Блоков</div>

            </div>

            <button class="btn__primary">
                <span>20</span>
                <img src="~@/assets/images/diamond__wallet.png" alt="">
            </button>

        </div>

    </div>

</template>

<script>
import '../../../styles/components/shop/boost.scss';
export default {
    name: "Boost",
};
</script>