<template>

    <div class="ranking">

        <div class="ranking__header">
            
            <button class="back" @click="$emit('close-ranking')">
                <img src="~@/assets/images/arrow-left.png" alt="">
                <span>Назад</span>
            </button>

            <p class="page__title">Топ 100 игроков</p>
            <p class="page__span">Отображаются пользователи с самым большим фармом за 5 минут</p>

        </div>

        <div class="ranking__userinfo">

            <div class="profile">

                <div class="avatar">
                    <img src="~@/assets/images/header/avatar.png" alt="">
                </div>

                <div class="nickname">Username</div>

            </div>

            <div class="block__count">
                <span class="count">100</span>
                <img src="~@/assets/images/block__wallet.png" alt="">
            </div>

        </div>

        <div class="ranking__wrapper">

            <div class="ranking__list--header">
                <span>Место</span>
                <span>Блоки за 5 минут</span>
            </div>

            <div class="ranking__list">

                <div class="ranking__list__item">

                    <div class="userinfo">

                        <div class="top">
                            <img src="~@/assets/images/ranking/1.png" alt="">
                        </div>

                        <div class="user__icon">
                            <img src="~@/assets/images/ranking/user.png" alt="">
                        </div>

                        <p class="username">Username</p>

                    </div>

                    <div class="block__count">
                        <span class="count">247M</span>
                        <img src="~@/assets/images/block__wallet.png" alt="">
                    </div>

                </div>

                <div class="ranking__list__item">

                    <div class="userinfo">

                        <div class="top">
                            <img src="~@/assets/images/ranking/2.png" alt="">
                        </div>

                        <div class="user__icon">
                            <img src="~@/assets/images/ranking/user.png" alt="">
                        </div>

                        <p class="username">Username</p>

                    </div>

                    <div class="block__count">
                        <span class="count">120M</span>
                        <img src="~@/assets/images/block__wallet.png" alt="">
                    </div>

                </div>

                <div class="ranking__list__item">

                    <div class="userinfo">

                        <div class="top">
                            <img src="~@/assets/images/ranking/3.png" alt="">
                        </div>

                        <div class="user__icon">
                            <img src="~@/assets/images/ranking/user.png" alt="">
                        </div>

                        <p class="username">Username</p>

                    </div>

                    <div class="block__count">
                        <span class="count">55M</span>
                        <img src="~@/assets/images/block__wallet.png" alt="">
                    </div>

                </div>

                <div class="ranking__list__item">

                    <div class="userinfo">

                        <div class="top">4</div>

                        <div class="user__icon">
                            <img src="~@/assets/images/ranking/user.png" alt="">
                        </div>

                        <p class="username">Username</p>

                    </div>

                    <div class="block__count">
                        <span class="count">10M</span>
                        <img src="~@/assets/images/block__wallet.png" alt="">
                    </div>

                </div>

                <div class="ranking__list__item">

                    <div class="userinfo">

                        <div class="top">5</div>

                        <div class="user__icon">
                            <img src="~@/assets/images/ranking/user.png" alt="">
                        </div>

                        <p class="username">Username</p>

                    </div>

                    <div class="block__count">
                        <span class="count">2M</span>
                        <img src="~@/assets/images/block__wallet.png" alt="">
                    </div>

                </div>

                <div class="ranking__list__item">

                    <div class="userinfo">

                        <div class="top">6</div>

                        <div class="user__icon">
                            <img src="~@/assets/images/ranking/user.png" alt="">
                        </div>

                        <p class="username">Username</p>

                    </div>

                    <div class="block__count">
                        <span class="count">247K</span>
                        <img src="~@/assets/images/block__wallet.png" alt="">
                    </div>

                </div>

                <div class="ranking__list__item">

                    <div class="userinfo">

                        <div class="top">7</div>

                        <div class="user__icon">
                            <img src="~@/assets/images/ranking/user.png" alt="">
                        </div>

                        <p class="username">Username</p>

                    </div>

                    <div class="block__count">
                        <span class="count">150K</span>
                        <img src="~@/assets/images/block__wallet.png" alt="">
                    </div>

                </div>

                <div class="ranking__list__item">

                    <div class="userinfo">

                        <div class="top">8</div>

                        <div class="user__icon">
                            <img src="~@/assets/images/ranking/user.png" alt="">
                        </div>

                        <p class="username">Username</p>

                    </div>

                    <div class="block__count">
                        <span class="count">125K</span>
                        <img src="~@/assets/images/block__wallet.png" alt="">
                    </div>

                </div>

                <div class="ranking__list__item">

                    <div class="userinfo">

                        <div class="top">9</div>

                        <div class="user__icon">
                            <img src="~@/assets/images/ranking/user.png" alt="">
                        </div>

                        <p class="username">Username</p>

                    </div>

                    <div class="block__count">
                        <span class="count">87K</span>
                        <img src="~@/assets/images/block__wallet.png" alt="">
                    </div>

                </div>

                <div class="ranking__list__item">

                    <div class="userinfo">

                        <div class="top">10</div>

                        <div class="user__icon">
                            <img src="~@/assets/images/ranking/user.png" alt="">
                        </div>

                        <p class="username">Username</p>

                    </div>

                    <div class="block__count">
                        <span class="count">20K</span>
                        <img src="~@/assets/images/block__wallet.png" alt="">
                    </div>

                </div>

            </div>

        </div>

    </div>

</template>

<script>
import '../styles/components/Ranking.scss';

export default {
    name: "RankingView",
};
</script>