<template>

    <div class="referal">

        <p class="page__title">Реферальная система</p>

        <div class="referal__level__list">

            <div class="referal__level__item">

                <p class="level__num">Уровень 1</p>

                <div class="ref__num">

                    <p class="score">0</p>

                    <div class="icon">
                        <img src="~@/assets/images/people.png" alt="">
                    </div>

                </div>

            </div>

            <div class="referal__level__item">

                <p class="level__num">Уровень 2</p>

                <div class="ref__num">

                    <p class="score">0</p>

                    <div class="icon">
                        <img src="~@/assets/images/people.png" alt="">
                    </div>

                </div>

            </div>

            <div class="referal__level__item">

                <p class="level__num">Уровень 3</p>

                <div class="ref__num">

                    <p class="score">0</p>

                    <div class="icon">
                        <img src="~@/assets/images/people.png" alt="">
                    </div>

                </div>

            </div>

            <div class="referal__level__item">

                <p class="level__num">Уровень 4</p>

                <div class="ref__num">

                    <p class="score">0</p>

                    <div class="icon">
                        <img src="~@/assets/images/people.png" alt="">
                    </div>

                </div>

            </div>

            <div class="referal__level__item">

                <p class="level__num">Уровень 5</p>

                <div class="ref__num">

                    <p class="score">0</p>

                    <div class="icon">
                        <img src="~@/assets/images/people.png" alt="">
                    </div>

                </div>

            </div>

        </div>

        <div class="referal__button">
            <button class="btn__primary referal__invite">Пригласить</button>
            <button class="btn__primary referal__copy"><img src="~@/assets/images/copy.png" alt=""></button>
        </div>

    </div>

</template>

<script>
    import '../styles/components/Referal.scss';

    export default {
        name: "ReferalView",
    };
</script>
