<template>
    <div class="bonus">
        <p class="page__title">Ежедневный бонус</p>
        <p class="page__span">Получайте от 1 до 100 алмазов <br> каждый день!</p>

        <div class="bonus__image">
            <img src="~@/assets/images/bonus/bonus.png" alt="">
        </div>

        <div class="bonus__info">
            <div class="bonus__timer">
                <span>До следующего бонуса:</span>
                <span class="timer">{{ formattedTime }}</span>
            </div>

            <button 
                class="btn__primary"
                :completed="isBonusClaimed ? true : null" 
                :disabled="isBonusClaimed || timeLeft > 0" 
                @click="claimBonus"
            >
                {{ isBonusClaimed ? 'Бонус получен' : 'Получить бонус' }}
            </button>
        </div>
    </div>
</template>

<script>
import '../styles/components/Bonus.scss';

export default {
    name: "BonusView",
    data() {
        return {
            timeLeft: 180,
            isBonusClaimed: false,
            timerInterval: null,
        };
    },
    computed: {
        formattedTime() {
            const minutes = Math.floor(this.timeLeft / 60);
            const seconds = this.timeLeft % 60;
            return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
        },
    },
    methods: {
        startTimer() {
            this.timerInterval = setInterval(() => {
                if (this.timeLeft > 0) {
                    this.timeLeft--;
                } else {
                    clearInterval(this.timerInterval);
                }
            }, 1000);
        },
        claimBonus() {
            this.isBonusClaimed = true;
        },
    },
    mounted() {
        this.startTimer();
    },
    beforeUnmount() {
        clearInterval(this.timerInterval);
    },
};
</script>