<template>
    <div class="modal__title">
        <p class="title">Купить алмазы</p>
    </div>

    <div class="buy__diamonds">
        
        <div class="buy__diamonds__item">

            <div class="buy__diamonds__item--icon">
                <img src="~@/assets/images/shop/diamond/1.png" alt="">
            </div>

            <div class="buy__diamonds__item--info">

                <div class="buy__diamonds__item--count">
                    <span class="count">100</span>
                    <span>алмазов</span>
                </div>

                <button class="btn__primary">0.99 $</button>

            </div>

        </div>

        <div class="buy__diamonds__item">

            <div class="buy__diamonds__item--icon">
                <img src="~@/assets/images/shop/diamond/2.png" alt="">
            </div>

            <div class="buy__diamonds__item--info">

                <div class="buy__diamonds__item--count">
                    <span class="count">515</span>
                    <span>алмазов</span>
                </div>

                <button class="btn__primary">4.99 $</button>

            </div>

        </div>

        <div class="buy__diamonds__item">

            <div class="buy__diamonds__item--icon">
                <img src="~@/assets/images/shop/diamond/3.png" alt="">
            </div>

            <div class="buy__diamonds__item--info">

                <div class="buy__diamonds__item--count">
                    <span class="count">1 035</span>
                    <span>алмазов</span>
                </div>

                <button class="btn__primary">9.99 $</button>

            </div>

        </div>

        <div class="buy__diamonds__item">

            <div class="buy__diamonds__item--icon">
                <img src="~@/assets/images/shop/diamond/4.png" alt="">
            </div>

            <div class="buy__diamonds__item--info">

                <div class="buy__diamonds__item--count">
                    <span class="count">2 650</span>
                    <span>алмазов</span>
                </div>

                <button class="btn__primary">24.99 $</button>

            </div>

        </div>

        <div class="buy__diamonds__item">

            <div class="buy__diamonds__item--icon">
                <img src="~@/assets/images/shop/diamond/5.png" alt="">
            </div>

            <div class="buy__diamonds__item--info">

                <div class="buy__diamonds__item--count">
                    <span class="count">10 850</span>
                    <span>алмазов</span>
                </div>

                <button class="btn__primary">99.99 $</button>

            </div>

        </div>

        <div class="buy__diamonds__item">

            <div class="buy__diamonds__item--icon">
                <img src="~@/assets/images/shop/diamond/6.png" alt="">
            </div>

            <div class="buy__diamonds__item--info">

                <div class="buy__diamonds__item--count">
                    <span class="count">22 100</span>
                    <span>алмазов</span>
                </div>

                <button class="btn__primary">199 $</button>

            </div>

        </div>

        <div class="buy__diamonds__item">

            <div class="buy__diamonds__item--icon">
                <img src="~@/assets/images/shop/diamond/7.png" alt="">
            </div>

            <div class="buy__diamonds__item--info">

                <div class="buy__diamonds__item--count">
                    <span class="count">56 200</span>
                    <span>алмазов</span>
                </div>

                <button class="btn__primary">499 $</button>

            </div>

        </div>

        <div class="buy__diamonds__item">

            <div class="buy__diamonds__item--icon">
                <img src="~@/assets/images/shop/diamond/8.png" alt="">
            </div>

            <div class="buy__diamonds__item--info">

                <div class="buy__diamonds__item--count">
                    <span class="count">115 800</span>
                    <span>алмазов</span>
                </div>

                <button class="btn__primary">999 $</button>

            </div>

        </div>

    </div>

</template>

<script>
import '../../../styles/components/shop/buyDiamond.scss';
export default {
    name: "BuyDiamonds",
};
</script>
