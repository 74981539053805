<template>

    <div class="home">

        <div class="score__panel glitter">
            <p class="score">1 023 432</p>
            <img src="~@/assets/images/block__wallet.png" alt="">
        </div>

        <div class="claim__btn"
            @click="$modal.show({ 
                type: 'center', 
                title: 'Получены блоки', 
                message: block,
                icon: success,
                hasAction: true,
                actionText: 'Получить'
            })"
        >
            <img src="~@/assets/images/pick.png" alt="">
        </div>
        
    </div>

</template>

<script>
import '../styles/components/Home.scss';

import successIcon from '@/assets/images/modal/success.png';
import blockImage from '@/assets/images/block__wallet.png';

export default {
    name: "HomeView",
    data() {
        return {
            block: `<span class="claim__block__count">59 </span> <span class="claim__block__images"><img src="`+ blockImage + `" alt=""></span>`,
            success: successIcon,
        };
    },
};
</script>
